import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { Apiurl } from 'app/apiconfig';

const baseQuery = fetchBaseQuery({ baseUrl: Apiurl[0].api_url });

const rootApi = createApi({
  baseQuery,
  tagTypes: [
    'UserData',
  ],
  endpoints: (builder) => ({})
});

export default rootApi;
