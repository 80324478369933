import { configureStore } from '@reduxjs/toolkit';
import rootReducer from './reducers/rootReducer';
import rootApi from './api/rootApi';
import { setupListeners } from '@reduxjs/toolkit/query';

const store = configureStore({
  reducer: rootReducer,
  // Adding the api middleware enables caching, invalidation, polling,
  // and other useful features of `rtk-query`.
  middleware: (getDefaultMiddleware) => getDefaultMiddleware().concat(rootApi.middleware),
  devTools: process.env.NODE_ENV !== 'production'  
});

//required for refetchOnFocus/refetchOnReconnect behaviors
setupListeners(store.dispatch);

export default store;
